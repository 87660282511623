import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyleSheetManager } from 'styled-components';

import GlobalStyling from 'web/components/GlobalStyling';
import THEME from 'web/theme';
import App from 'web/App';
import { AuthProvider } from 'web/config/auth';
import apolloClient from 'web/config/graphql';

const propBlacklist = ['svgWidth', 'fullWidth', 'active'];

ReactDOM.createRoot(document.getElementById('app')).render(
	<AuthProvider>
		<ApolloProvider client={apolloClient}>
			<StyleSheetManager shouldForwardProp={p => !propBlacklist.includes(p)}>
				<ThemeProvider theme={THEME}>
					<GlobalStyling />
					<App />
				</ThemeProvider>
			</StyleSheetManager>
		</ApolloProvider>
	</AuthProvider>
);
