import React, { useState, useMemo, useContext, useEffect } from 'react';

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	const checkAuth = async () => {
		setLoading(true);

		const response = await fetch('/auth/user');

		const user = await response.json();

		if (!user.isAuthenticated) {
			setIsAuthenticated(false);
			setUser(null);
		} else {
			setIsAuthenticated(true);
			setUser(user);
		}

		setLoading(false);
	};

	useMemo(() => {
		!isAuthenticated && checkAuth();
	}, []);

	return (
		<AuthContext.Provider
			value={{
				isAuthenticated,
				user,
				loading,
				handleRedirectCallback: async () => {
					await checkAuth();
					window.location = '/';
				},
				checkAccess: roles => roles?.split(',')?.filter(role => user.roles.includes(role))?.length > 0 || user.roles.includes('admin'),
				login: () => (window.location = '/auth/signin'),
				logout: () => (window.location = '/auth/signout'),
			}}>
			{children}
		</AuthContext.Provider>
	);
}

export function Authorized({ children }) {
	const { isAuthenticated } = useAuth();

	if (!isAuthenticated) {
		return <div />;
	}

	return <>{children}</>;
}

export function UnAuthorized({ children }) {
	const { isAuthenticated } = useAuth();

	if (isAuthenticated) {
		return <div />;
	}

	return <>{children}</>;
}
